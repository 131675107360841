.unlock {
    width: 80px;
    height: 80px;
    background-color: #E3F3EE;
    border-radius: 50%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.formItem {
    width: 100%;
    max-width: 300px;
}

.payItem {
    flex: 0 0 30%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin: 0;
    flex-direction: row-reverse !important;
    justify-content: flex-end;
    background-color: rgba(0, 0, 0, 0.02);
    padding: 0 15px;
    gap: 16px;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.06);
    border-radius: 2px 2px 0 0;
}
.payItem > div:first-child {
    padding: 0;
}
.payItem > div:last-child {
    flex-grow: unset;
}