.bf-content {
  height: 200px !important;
}

.ant-table-tbody .ant-checkbox-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ant-table-tbody .ant-table-row-expand-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.temp-row111213123 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
